

window.$ = require('jquery');
require('./bootstrap');

let sortable = require("jquery-ui/ui/widgets/sortable");
import {customToast} from "./toast"

window.toast = customToast;


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


