import {Toast} from "bootstrap";

const TOAST_DELAY = 5000;
let customToast = {
    // TOAST function
    /**
     * Toast function parameters
     * @param type - simple, full
     * @param style - danger, warning, success, info, dark, white, light, primary, secondary
     * @param delay - delay in ms
     * @param body - content
     * @param icon - bootstrap icon
     * @param title - full toast header text
     */
    showToast: function (type, style, delay = 0, body, icon, title ){

        const styles = {
            "danger":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-danger"
                ],
                "border":"border-danger"
            },
            "warning":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-warning"
                ],
                "border":"border-warning"
            },
            "success":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-success"
                ],
                "border":"border-success"
            },
            "info":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-info"
                ],
                "border":"border-info"
            },
            "dark":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-dark"
                ],
                "border":"border-dark"
            },
            "white":{
                "btnClose":"",
                "main":[
                    "text-dark",
                    "bg-white",
                    "border-bottom"
                ],
                "border":"border-dark"
            },
            "light":{
                "btnClose":"",
                "main":[
                    "text-dark",
                    "bg-light",
                    "border-bottom"
                ],
                "border":"border-dark"
            },
            "primary":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-primary"
                ],
                "border":"border-primary"
            },
            "secondary":{
                "btnClose":"btn-close-white",
                "main":[
                    "text-white",
                    "bg-secondary"
                ],
                "border":"border-secondary"
            }
        }

        const item = document.createElement('div');

        item.setAttribute('role', 'alert');
        item.setAttribute('aria-live', 'assertive');
        item.setAttribute('aria-atomic', 'true');

        switch (type) {
            case 'simple':

                if (!style || !styles.hasOwnProperty(style)) {
                    style = "primary"; //default style
                }
                const simpleStyle = styles[style];

                item.classList.add('toast', 'align-items-center', 'border-0' );
                simpleStyle.main.forEach(value => {
                    item.classList.add(value);
                });

                item.innerHTML = `<div class="d-flex">
                          <div class="me-1 ms-3 m-auto fs-4"><i class="${icon}"></i></div>
                          <div class="toast-body">${body}</div>
                          <button type="button" class="btn-close ${simpleStyle.btnClose} me-2 mt-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                       </div>`;

                break;
            case 'full':
                item.classList.add('toast' );

                if (!style || !styles.hasOwnProperty(style)) {
                    style = "white"; //default style
                }
                let fullStyle = styles[style];
                fullStyle.main.forEach(value => {
                    item.classList.add(value);
                });

                item.innerHTML = `<div class="toast-header">
                          <div class="me-2 ms-0 m-auto"><i class="${icon}"></i></div>
                          <strong class="me-auto">${title}</strong>
                          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                       </div>
                       <div class="toast-body">${body}</div>`;

                break;
            default:
                console.log(`Toast error: no type`);
        }

        document.querySelector('#toast-main').appendChild(item);

        const opts = {
            autohide: (delay > 0 && typeof delay === 'number'),
        };
        if (delay > 0 && typeof delay === 'number') {
            opts['delay'] = delay;
        }

        const toast = new Toast(item, opts);
        toast.show();

        return toast;
    },

    // Often used toasts
    /**
     * Toast function parameters
     * @param type - deleted, added, updated, ok, error
     * @param message - jei tuscias tai default
     */
    showMadeToast: function (type, message = '') {
        let icon = "check-circle",
            style = "success";
        switch (type) {
            case "deleted":
                message = message === '' ? "Sėkmingai ištrinta" : message;
                break;
            case "added":
            case "inserted":
                message = message === '' ? "Sėkmingai sukurta" : message;
                break;
            case "updated":
                message = message === '' ? "Sėkmingai atnaujinta" : message;
                break;
            case "ok":
                message = message === '' ? "Sėkmingai atlikta" : message;
                break;
            case "error":
                message = message === '' ? "Klaida, veiksmas nebuvo atliktas" : message;
                style = "danger";
                icon = "x-circle";
                break;
        }

        return this.showToast("simple", style, TOAST_DELAY, message, "bi bi-" + icon);
    },
    // write toast to session storage
    addSessionToast: function (type, message = '') {
        // console.log("toast to session");
        let toastArray = [],
            toast = {
                type: type,
                message: message
            };

        if (sessionStorage.getItem("toast")) {
            toastArray = JSON.parse(sessionStorage.getItem("toast"));

        }

        toastArray.push(toast)
        sessionStorage.setItem('toast', JSON.stringify(toastArray))
    },
    showSessionToasts: async function (){
        if (sessionStorage.getItem("toast")) {
            let sessionToasts = JSON.parse(sessionStorage.getItem("toast"));
            console.log(sessionToasts);
            for (let i = 0; i < sessionToasts.length; i++) {
                setTimeout(function timer() {
                    if(sessionToasts[i].message !== ''){
                        customToast.showMadeToast(sessionToasts[i].type, sessionToasts[i].message);
                    }else{
                        customToast.showMadeToast(sessionToasts[i].type);
                    }
                }, i * 1000);
            }
            await customToast.deleteSessionToasts();
        }
    },
    deleteSessionToasts: async function (){
        sessionStorage.removeItem('toast');
    }

}

export {customToast}
